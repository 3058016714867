import { memo, useRef, useState } from 'react'
import { LazyMount } from '@app/components/lazy/lazymount'
import { RenderInnerPagesPaging } from '../../lists/render/issues/pages-paging'
import { Analytic } from '@app/types'
import { dataListStyle } from './head-style'
import { PageBar } from '../../lists/render/issues/page-bar'
import ErrorBoundary from '../../error-boundary'

// issue card paging
const PageCardComponent = ({
  pageUrl,
  liveData,
  handleMainClick,
  activeSubscription,
  storageless,
}: {
  pageUrl: string
  liveData?: Analytic[]
  handleMainClick?: any // modal
  activeSubscription?: boolean
  fetchedData?: boolean
  storageless?: boolean
}) => {
  const inputRef = useRef<{
    setIndex: (x: any) => void
    refetch: () => Promise<void>
    setSelectedIndex: (x: any) => void
  }>(null)
  const [blockLive, setLive] = useState<boolean>(false)

  const setLiveData = async () => {
    if (!blockLive && inputRef.current) {
      await inputRef.current?.refetch()
    }
    setLive((x) => !x)
  }

  return (
    <ErrorBoundary>
      <PageBar
        setLiveData={setLiveData}
        liveData={liveData && !!liveData.length}
        blockLive={blockLive}
        activeSubscription={activeSubscription}
        storageless={storageless}
      />
      <LazyMount className={dataListStyle}>
        <RenderInnerPagesPaging
          pageUrl={pageUrl}
          liveData={liveData}
          handleMainClick={handleMainClick}
          blockLive={blockLive}
          ref={inputRef}
        />
      </LazyMount>
    </ErrorBoundary>
  )
}

export const PageCard = memo(PageCardComponent)

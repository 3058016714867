import React, { useState, useCallback, memo, Fragment, useMemo } from 'react'
import { InputActions } from '@app/components/general'
import { domainList as dmList } from '@app/utils'
import { GrAdd, GrChapterAdd } from 'react-icons/gr'
import { InputHeaders } from './forms/input-headers'
import { useInputActions, useInputHeader } from './hooks'
import { useWebsiteContext } from '../providers/website'
import {
  AccessibilityStandardKeys,
  Standard,
  WCAGSelectInput,
} from './select/select-input'
import { FormControl } from './form-control'
import { useAuthContext } from '../providers/auth'
import { Checkbox } from './check-box'
import { RunnerSelect } from './runner-select'
import {
  SitemapKeys,
  SiteMapOption,
  SitemapSelectInput,
} from './select/sitemap-input'
import { useTranslation } from '@app/lib/hooks/useTranslation'
import { Theme, ThemeInput, ThemeKeys } from './select/theme-input'
import {
  baseFormDialogButtonStyle,
  getBtnDialogStyle,
} from './website/form-dialog-styles'
import { CrawlLimitBoxInput } from './inputs/crawl-limit-input'
import { BlacklistInput } from './inputs/blacklist-input'
import { ProxyBoxInput } from './inputs/proxy-input'
import { HideElementsInput } from './inputs/hide-elements-input'
import { RootSelectorInput } from './inputs/root-selector-input'
import { AgentInput } from './inputs/ua-input'
import { CrawlDelayBoxInput } from './inputs/crawl-delay-input'
import { routeParseAbsolute } from '@app/lib/router-handler'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@app/@/components/ui/tooltip'
import { IgnoreInput } from './select/ignore-input'
import { useKayleRules } from '@app/data/formatters/use-kayle-rules'
import { useToast } from '@app/@/components/ui/use-toast'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@app/@/components/ui/dialog'
import { twMerge } from 'tailwind-merge'
import { Input } from '@app/@/components/ui/input'
import { Button } from '@app/@/components/ui/button'
import { UploadIcon } from '@radix-ui/react-icons'
import { WebsitesUpload } from './website/websites-upload'

const domainList = [...dmList, 'none']

interface FormDialogProps {
  buttonTitle?: string | JSX.Element
  okPress?: (a: any) => void
  buttonStyles?: string
  icon?: boolean | 'add' // show btn with icon
  iconButton?: boolean
  dialogDisabled?: boolean
  translation?: string
}

interface InputHead {
  key: string
  value: string
}

const checkBoxContainerStyles =
  'flex py-2.5 place-items-center min-w-[85px] gap-x-1.5'
const rowCss = `flex flex-wrap place-items-center gap-x-3 gap-y-3`
const labelledby = 'form-dialog-title'
const describedby = 'form-dialog-description'

// validate the headers inputs and send
const validateHeaders = (object: InputHead[]) => {
  const headers = []

  let i = 0

  // allow up to 50 headers
  for (const p of object) {
    const { key, value } = p

    if (key && value) {
      headers.push(p)
    }
    if (i > 50) {
      break
    }
    i++
  }

  return headers
}

export function FormDialogWrapper({
  buttonTitle = 'subscribe',
  okPress,
  buttonStyles = '',
  icon,
  iconButton,
  dialogDisabled,
  translation,
}: FormDialogProps) {
  const { t, lang } = useTranslation(translation || 'dashboard')
  const { account } = useAuthContext()
  const { addWebsite } = useWebsiteContext()
  const activeSubscription = account?.activeSubscription

  // custom state
  const [open, setOpen] = useState<boolean>(false)
  const [websitUrl, setUrl] = useState<string>('')
  const [rootElement, setRootSelector] = useState<string>('')
  const [hideElements, setHiddenElements] = useState<string>('')
  const [warningsEnabled, setWarningsEnabled] = useState<boolean>(true)
  const [mobileViewport, setMobile] = useState<boolean>(false)
  const [subdomains, setSubdomains] = useState<boolean>(false)
  const [sitemap, setSitemap] = useState<number>(0)
  const [tld, setTld] = useState<boolean>(false)
  const [ua, setUserAgent] = useState<string>('')
  const [proxy, setProxy] = useState<string>('')
  const [standard, setWCAGStandard] = useState<AccessibilityStandardKeys>(
    Standard[Standard.WCAG2AA] as AccessibilityStandardKeys
  )
  const [theme, setTheme] = useState<ThemeKeys>(
    Theme[Theme.system] as ThemeKeys
  )
  const [robots, setRobots] = useState<boolean>(!!activeSubscription)
  const [runners, setRunners] = useState<string[]>([])
  const [monitoringEnabled, setMonitoring] =
    useState<boolean>(!!activeSubscription)
  const [crawlDelay, setCrawlDelay] = useState<number>()
  const [crawlLimit, setCrawlLimit] = useState<number>()
  const [blacklist, setBlackList] = useState<string>()
  const { ignore, setIgnore, ignoreList, onLoadRulesEvent } = useKayleRules({
    runners,
    lang,
  })
  const [urlListVisible, setToggleUrlList] = useState<boolean>(false)
  const [websiteList, setWebsiteList] = useState<string[]>([])

  const { toast } = useToast()

  const headers = useInputHeader()
  const actions = useInputActions()

  const handleClickOpen = useCallback(() => setOpen(true), [setOpen])

  const onChangeText = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setUrl(event.target.value)
    },
    [setUrl]
  )

  const onBlacklistChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setBlackList(event.target.value)
    },
    [setBlackList]
  )

  const onStandardChange = useCallback(
    (event: React.ChangeEvent<any>) => setWCAGStandard(event.target.value),
    [setWCAGStandard]
  )

  const onThemeChange = useCallback(
    (event: React.ChangeEvent<any>) => setTheme(event.target.value),
    [setTheme]
  )

  const onCrawlLimitChange = useCallback(
    (event: React.ChangeEvent<any>) =>
      setCrawlLimit(Number(event.target.value)),
    [setCrawlLimit]
  )

  const onRunnerEvent = useCallback(
    (event: string[]) => setRunners(event),
    [setRunners]
  )
  const handleClose = useCallback(() => {
    setOpen(false)
    setUrl('')
  }, [setOpen, setUrl])

  const onToggleWarnings = useCallback(
    () => setWarningsEnabled((x) => !x),
    [setWarningsEnabled]
  )

  const onToggleUrlListEvent = () => {
    setToggleUrlList((x) => !x)
  }

  // fields
  const customActions = actions.customActions
  const customActionFields = actions.customFields
  // headers
  const customHeader = headers.customHeader
  const customFields = headers.customFields

  const crawlValidUrl = t('enter-valid-url')

  const defaultRunnerData = useMemo(
    () => runners.map((runner) => runner),
    [runners]
  )

  // main form submission of data
  const submit = useCallback(
    async (event: any) => {
      event?.preventDefault()

      // display demo dialog message
      if (dialogDisabled) {
        return
      }

      const websiteTargetUrls = !urlListVisible
        ? websitUrl.split(',')
        : websiteList

      for (let i = 0; i < websiteTargetUrls.length; i++) {
        const u = websiteTargetUrls[i]
        const _websitUrl = u

        if (!_websitUrl || (_websitUrl && _websitUrl === 'undefined')) {
          // prevent empty
          toast({
            title: crawlValidUrl,
            variant: 'destructive',
          })
          continue
        }

        handleClose()

        let cleanUrl = String(_websitUrl).replace(/^(?:https?:\/\/)?/i, '')

        let tpt = 'https'

        if (_websitUrl.includes('http://')) {
          tpt = 'http'
        }

        let urlBase = cleanUrl.includes('://') ? '' : `://`
        let blockExt = false

        if (cleanUrl.includes('localhost:')) {
          blockExt = true
        }

        // determine whether to add an extension or not
        const ex =
          blockExt ||
          cleanUrl.includes('.') ||
          domainList.some((element: any) => cleanUrl.includes(element))
            ? ''
            : '.com'

        const websiteUrlTarget = `${tpt}${urlBase}${cleanUrl}${ex}`.replaceAll(
          ' ',
          ''
        )

        const websiteCustomHeaders = customHeader
          ? validateHeaders(customFields)
          : null

        // make all paths start with slash
        const websiteActions = customActions
          ? customActionFields.map((items) => {
              const pathName =
                items.path && items.path[0] === '/'
                  ? items.path
                  : `/${items.path}`

              return {
                ...items,
                path: pathName,
              }
            })
          : null

        const params = {
          url: websiteUrlTarget.trim(),
          customHeaders: websiteCustomHeaders,
          mobile: mobileViewport,
          ua,
          standard,
          actions: websiteActions,
          robots,
          subdomains,
          tld,
          runners,
          proxy,
          sitemap,
          monitoringEnabled: activeSubscription ? monitoringEnabled : true,
          crawlDelay,
          rootElement,
          hideElements: hideElements ? hideElements.split(',') : [],
          theme: [theme],
          warningsEnabled,
          ignore,
          crawlLimit,
          blacklist:
            blacklist && blacklist.length ? blacklist.split(',') : undefined,
        }

        // we need to fix the session re-routing for multi form uploads.
        if (!activeSubscription) {
          if (typeof sessionStorage !== 'undefined') {
            // use-local storage instead with a date stamp - session does not persist between docs
            sessionStorage.setItem(
              '@app/subscribe-website',
              JSON.stringify(params)
            )
          }
          window.location.href = routeParseAbsolute('/payments', lang)
          return
        }

        // CLOSE pre-optimistic prevent dialog unmount state error
        if (i + 1 == websiteTargetUrls.length) {
          setWebsiteList([])
          if (urlListVisible) {
            setToggleUrlList(false)
          }
        }

        if (okPress && typeof okPress === 'function') {
          try {
            await okPress(params)
          } catch (e) {
            console.error(e)
          }
        } else {
          try {
            await addWebsite(
              {
                variables: params,
              },
              !i // first one display tooltip
            )
          } catch (e) {
            console.error(e)
          }
        }
      }
    },
    [
      lang,
      crawlValidUrl,
      crawlLimit,
      blacklist,
      handleClose,
      addWebsite,
      okPress,
      websitUrl,
      mobileViewport,
      standard,
      ua,
      ignore,
      customFields,
      customHeader,
      customActionFields,
      customActions,
      robots,
      subdomains,
      tld,
      runners,
      proxy,
      sitemap,
      monitoringEnabled,
      crawlDelay,
      activeSubscription,
      rootElement,
      hideElements,
      theme,
      dialogDisabled,
      warningsEnabled,
      toast,
      urlListVisible,
      websiteList,
      setToggleUrlList,
    ]
  )

  const onChangeUA = (event: React.ChangeEvent<HTMLInputElement>) =>
    setUserAgent(event.target.value)

  const onChangeProxy = (event: React.ChangeEvent<HTMLInputElement>) =>
    setProxy(event.target.value)

  const onChangeRootSelector = (event: React.ChangeEvent<HTMLInputElement>) => {
    const nextValue = event?.target?.value
    const intValue = nextValue && isNaN(parseInt(nextValue[0]))
    const symbolBlock =
      nextValue &&
      ['*', '&', '^', '%', '$', '@', '!', '(', '}', ')', '{', '|'].includes(
        nextValue[0]
      )
    // starts with char or #,., element

    if ((nextValue && !intValue) || symbolBlock) {
      return
    }

    setRootSelector(nextValue)
  }

  const onChangeHideElements = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const nextValue = event?.target?.value
      const intValue = nextValue && isNaN(parseInt(nextValue[0]))
      const symbolBlock =
        nextValue &&
        ['*', '&', '^', '%', '$', '@', '!', '(', '}', ')', '{', '|'].includes(
          nextValue[0]
        )
      // starts with char or #,., element

      if ((nextValue && !intValue) || symbolBlock) {
        return
      }

      setHiddenElements(nextValue)
    },
    [setHiddenElements]
  )

  const onChangeCrawlDelay = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) =>
      setCrawlDelay(Math.min(Number(event.target.value), 2000)),
    [setCrawlDelay]
  )

  // toggle actions form
  const onChangeActionsEvent = () =>
    actions.setCustomActions((v: boolean) => !v)

  // toggle headers form
  const onChangeHeadersEvent = () => headers.setCustomHeader((v: boolean) => !v)

  const onMobileViewPortEvent = () => setMobile(!mobileViewport)

  const onChangeRobotsEvent = () => setRobots((a: boolean) => !a)

  const onChangeSubdomainsEvent = () => setSubdomains((a: boolean) => !a)

  const onChangeTldEvent = () => setTld((a: boolean) => !a)

  const onChangeSitemapEvent = (event: React.ChangeEvent<HTMLInputElement>) =>
    setSitemap(SiteMapOption[event.target.value as SitemapKeys])

  const onChangeMonitoringEvent = () => setMonitoring((a: boolean) => !a)
  const closeModalText = t('common:close-modal')

  const storageless = [11, 20, 0].includes(account?.role || 0)

  const submitDisabled = urlListVisible ? !websiteList?.length : !websitUrl

  return (
    <Fragment>
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger
            onClick={handleClickOpen}
            className={baseFormDialogButtonStyle({ buttonStyles, iconButton })}
            type='button'
          >
            <div>
              {typeof buttonTitle === 'string'
                ? t(buttonTitle || 'subscribe')
                : buttonTitle}
              {icon ? (
                icon === 'add' ? (
                  <GrAdd className='grIcon text-xs' />
                ) : (
                  <GrChapterAdd className='grIcon text-xs' />
                )
              ) : null}
            </div>
          </TooltipTrigger>
          <TooltipContent>
            <p>{t('add-tip')}</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
      <Dialog
        open={open}
        onOpenChange={handleClose}
        aria-labelledby={labelledby}
        aria-describedby={describedby}
      >
        <DialogContent
          className='max-w-screen-lg'
          closeModalText={closeModalText}
        >
          <TooltipProvider>
            <div>
              <div className={'flex place-items-center gap-4'}>
                <div className='flex-1 rtl:text-right'>
                  <div className='flex place-items-center gap-2'>
                    <DialogHeader className='ltr:text-left rtl:text-right'>
                      <DialogTitle id={labelledby} className=''>
                        {t('subscribe')}
                      </DialogTitle>
                      <DialogDescription id={describedby}>
                        {t('subscribe-details')}
                      </DialogDescription>
                    </DialogHeader>
                  </div>
                </div>
              </div>
              <form onSubmit={submit} noValidate className='pt-4'>
                <div className='space-y-3'>
                  <div className='flex place-items-center gap-3'>
                    <div className={'w-full'}>
                      {urlListVisible ? (
                        <WebsitesUpload
                          setWebsiteList={setWebsiteList}
                          websiteList={websiteList}
                        />
                      ) : (
                        <>
                          <FormControl htmlFor='name'>
                            {t('common:enter-url')}
                          </FormControl>
                          <Input
                            autoFocus
                            onChange={onChangeText}
                            minLength={3}
                            className={`w-full`}
                            color='secondary'
                            value={websitUrl}
                            id='name'
                            placeholder={t('website-url')}
                            type='url'
                            required
                          />
                        </>
                      )}
                    </div>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <Button
                          onClick={onToggleUrlListEvent}
                          variant={urlListVisible ? 'secondary' : 'ghost'}
                          type='button'
                        >
                          <UploadIcon />
                          <span className='sr-only'>{t('import-list')}</span>
                        </Button>
                      </TooltipTrigger>
                      <TooltipContent>
                        <p>{t('import-list-d')}</p>
                      </TooltipContent>
                    </Tooltip>
                  </div>
                  <div className='overflow-y-auto max-h-[50vh] space-y-3 px-1'>
                    <div className={rowCss}>
                      {account?.role === 11 ? null : (
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <div className={checkBoxContainerStyles}>
                              <Checkbox
                                checked={mobileViewport}
                                onChange={onMobileViewPortEvent}
                                id={'mobile'}
                              />
                              <FormControl htmlFor='mobile' visible>
                                {t('mobile')}
                              </FormControl>
                            </div>
                          </TooltipTrigger>
                          <TooltipContent>
                            <p>{t('dashboard-inputs:mobile-tip')}</p>
                          </TooltipContent>
                        </Tooltip>
                      )}

                      <Tooltip>
                        <TooltipTrigger asChild>
                          <div className={checkBoxContainerStyles}>
                            <Checkbox
                              color='primary'
                              checked={robots}
                              onChange={onChangeRobotsEvent}
                              id={'robots'}
                            />
                            <FormControl htmlFor='robots' visible>
                              {t('robots')}
                            </FormControl>
                          </div>
                        </TooltipTrigger>
                        <TooltipContent>
                          <p>{t('dashboard-inputs:robots-tip')}</p>
                        </TooltipContent>
                      </Tooltip>

                      {account?.role === 11 ? null : (
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <div className={checkBoxContainerStyles}>
                              <Checkbox
                                checked={monitoringEnabled}
                                onChange={onChangeMonitoringEvent}
                                id={'monitoring'}
                              />
                              <FormControl htmlFor='monitoring' visible>
                                {t('monitor')}
                              </FormControl>
                            </div>
                          </TooltipTrigger>
                          <TooltipContent>
                            <p>{t('dashboard-inputs:monitor-tip')}</p>
                          </TooltipContent>
                        </Tooltip>
                      )}

                      <Tooltip>
                        <TooltipTrigger asChild>
                          <div className={checkBoxContainerStyles}>
                            <Checkbox
                              checked={warningsEnabled}
                              onChange={onToggleWarnings}
                              id={'warnings'}
                            />
                            <FormControl htmlFor='warnings' visible>
                              {t('warnings')}
                            </FormControl>
                          </div>
                        </TooltipTrigger>
                        <TooltipContent>
                          <p>{t('dashboard-inputs:warnings-tip')}</p>
                        </TooltipContent>
                      </Tooltip>

                      {account?.role === 11 ? null : (
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <div className={checkBoxContainerStyles}>
                              <Checkbox
                                checked={customActions}
                                onChange={onChangeActionsEvent}
                                id={'actions'}
                              />
                              <FormControl htmlFor='actions' visible>
                                {t('actions')}
                              </FormControl>
                            </div>
                          </TooltipTrigger>
                          <TooltipContent>
                            <p>{t('dashboard-inputs:actions-tip')}</p>
                          </TooltipContent>
                        </Tooltip>
                      )}

                      <Tooltip>
                        <TooltipTrigger asChild>
                          <div className={checkBoxContainerStyles}>
                            <Checkbox
                              checked={customHeader}
                              onChange={onChangeHeadersEvent}
                              id={'headers'}
                            />
                            <FormControl htmlFor='headers' visible>
                              {t('headers')}
                            </FormControl>
                          </div>
                        </TooltipTrigger>
                        <TooltipContent>
                          <p>{t('dashboard-inputs:headers-tip')}</p>
                        </TooltipContent>
                      </Tooltip>

                      <Tooltip>
                        <TooltipTrigger asChild>
                          <div className={checkBoxContainerStyles}>
                            <Checkbox
                              checked={subdomains}
                              onChange={onChangeSubdomainsEvent}
                              id={'subdomains'}
                            />
                            <FormControl htmlFor='subdomains' visible>
                              {t('subdomains')}
                            </FormControl>
                          </div>
                        </TooltipTrigger>
                        <TooltipContent>
                          <p>{t('dashboard-inputs:subdomains-tip')}</p>
                        </TooltipContent>
                      </Tooltip>

                      <Tooltip>
                        <TooltipTrigger asChild>
                          <div className={checkBoxContainerStyles}>
                            <Checkbox
                              checked={tld}
                              onChange={onChangeTldEvent}
                              id={'tlds'}
                            />
                            <FormControl htmlFor='tlds' visible>
                              {t('tlds')}
                            </FormControl>
                          </div>
                        </TooltipTrigger>
                        <TooltipContent>
                          <p>{t('dashboard-inputs:tlds-tip')}</p>
                        </TooltipContent>
                      </Tooltip>
                    </div>
                    <div className={rowCss}>
                      <WCAGSelectInput
                        standard={standard}
                        onStandardChange={onStandardChange}
                        spacing
                        borderLess
                      />

                      <SitemapSelectInput
                        sitemap={SiteMapOption[sitemap] as SitemapKeys}
                        onSitemapChange={onChangeSitemapEvent}
                        label={t('sitemap')}
                        labelOptions={t('sitemap-options')}
                        borderLess
                        t={t}
                      />

                      <RunnerSelect
                        cb={onRunnerEvent}
                        borderLess
                        defaultRunners={defaultRunnerData}
                      />

                      <ThemeInput
                        theme={theme}
                        onStandardChange={onThemeChange}
                        spacing
                        borderLess
                      />

                      <IgnoreInput
                        t={t}
                        onLoadRulesEvent={onLoadRulesEvent}
                        ignoreList={ignoreList}
                        setIgnore={setIgnore}
                        ignore={ignore}
                      />
                    </div>
                    <div className={rowCss}>
                      <AgentInput
                        onChangeUA={onChangeUA}
                        ua={ua}
                        id='ua-dialog'
                        activeSubscription={account.activeSubscription}
                        dialog
                      />

                      <CrawlDelayBoxInput
                        id='crawl-delay-dialog'
                        activeSubscription={account.activeSubscription}
                        value={crawlDelay}
                        onChange={onChangeCrawlDelay}
                        dialog
                      />
                      <RootSelectorInput
                        onChange={onChangeRootSelector}
                        value={rootElement}
                        id='root-dialog'
                        activeSubscription={account.activeSubscription}
                        dialog
                      />

                      <HideElementsInput
                        onChangeHideElements={onChangeHideElements}
                        hideElements={hideElements}
                        id='hide-dialog'
                        text={t('hide-elements')}
                        activeSubscription={account.activeSubscription}
                        dialog
                      />

                      <ProxyBoxInput
                        onChangeProxy={onChangeProxy}
                        value={proxy}
                        id='proxy-dialog'
                        text={t('proxy')}
                        activeSubscription={account.activeSubscription}
                        dialog
                      />

                      <CrawlLimitBoxInput
                        id='climit-dialog'
                        onChange={onCrawlLimitChange}
                        activeSubscription={account.activeSubscription}
                        dialog
                      />

                      <BlacklistInput
                        id='blacklist-dialog'
                        blacklist={blacklist}
                        onChangeBlacklist={onBlacklistChange}
                        activeSubscription={account.activeSubscription}
                        dialog
                      />
                    </div>

                    {customHeader ? <InputHeaders {...headers} /> : null}
                    {customActions ? <InputActions {...actions} /> : null}
                  </div>
                </div>
                <DialogFooter className='pt-3'>
                  <Button
                    disabled={submitDisabled}
                    type='submit'
                    className={twMerge(
                      getBtnDialogStyle(!submitDisabled),
                      'rounded'
                    )}
                  >
                    {t(
                      storageless ? 'dashboard:analyze' : 'dashboard:subscribe'
                    )}
                  </Button>
                </DialogFooter>
              </form>
            </div>
          </TooltipProvider>
        </DialogContent>
      </Dialog>
    </Fragment>
  )
}

export const FormDialog = memo(FormDialogWrapper)

import { memo, useRef, useState } from 'react'
import { LazyMount } from '@app/components/lazy/lazymount'
import { RenderInnerAnalyticsPaging } from '../../lists/render/issues/analytics-paging'
import { Analytic } from '@app/types'
import { dataListStyle } from './head-style'
import { PageIssuesBar } from '../../lists/render/issues/issues-bar'
import { SortDirection } from '../../dashboard-website-list'
import ErrorBoundary from '../../error-boundary'

type IssueCardProps = {
  pageUrl: string
  liveData?: Analytic[]
  activeSubscription?: boolean
  storageless?: boolean
  domain?: string
  blacklist?: string[]
  fetchedData?: boolean
  onSortEvent?(_: string, __: SortDirection): void
}

// issue card paging
const IssueCardComponent = ({
  pageUrl,
  liveData,
  activeSubscription,
  storageless,
  domain,
  blacklist,
  onSortEvent,
}: IssueCardProps) => {
  const inputRef = useRef<{
    setIndex: (x: any) => void
    refetch: () => Promise<void>
    setSelectedIndex: (x: any) => void
  }>(null)
  const [extraActive, setExtraActive] = useState<boolean | string>(false)
  const [filterActive, setFilter] = useState<'errors' | 'warnings'>()
  const [blockLive, setLive] = useState<boolean>(false)

  const setFilterActive = (x: 'errors' | 'warnings') => {
    if (inputRef.current) {
      inputRef.current?.setSelectedIndex(undefined)
      inputRef.current?.setIndex(0)
    }
    return setFilter(x)
  }

  const setLiveData = async () => {
    if (!blockLive && inputRef.current) {
      await inputRef.current?.refetch()
    }
    setLive((x) => !x)
  }

  return (
    <ErrorBoundary>
      <PageIssuesBar
        active={extraActive}
        pageUrl={pageUrl}
        activeSubscription={activeSubscription}
        storageless={storageless}
        filterActive={filterActive}
        setExtraActive={setExtraActive}
        setFilterActive={setFilterActive}
        setLiveData={setLiveData}
        onSortEvent={onSortEvent}
        liveData={liveData && !!liveData.length}
        blockLive={blockLive}
      />
      <LazyMount className={dataListStyle}>
        <RenderInnerAnalyticsPaging
          pageUrl={pageUrl}
          liveData={liveData}
          domain={domain}
          blacklist={blacklist}
          extraActive={extraActive}
          filterActive={filterActive}
          ref={inputRef}
          blockLive={blockLive}
        />
      </LazyMount>
    </ErrorBoundary>
  )
}

export const IssueCard = memo(IssueCardComponent)

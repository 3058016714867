import React, { Component, ReactNode } from 'react'
import withTranslation from 'next-translate/withTranslation'
import type { Translate } from 'next-translate'
import { UserManager } from '@app/managers'
import { FilterManager } from '@app/managers/filters'

interface ErrorBoundaryProps {
  children: ReactNode
  i18n: { t: Translate }
}

interface ErrorBoundaryState {
  hasError: boolean
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(_: Error): ErrorBoundaryState {
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.error('ErrorBoundary caught an error', error, errorInfo)
  }

  onLogout() {
    UserManager.clearUser()
    FilterManager.clearFilters()
    window.location.href = '/logout'
  }

  render() {
    const { t } = this.props?.i18n ?? { t: () => {} }
    // temp replace all errors
    const errorText = t('errors:auth-error', undefined, {
      returnObjects: false,
    })
      ?.replaceAll('<component>', '')
      ?.replaceAll('</component>', '')

    if (this.state.hasError) {
      return (
        <div className='space-y-2'>
          <p>{errorText}</p>
          <button
            type='button'
            onClick={this.onLogout}
            className='rounded border-2 px-3 py-1'
          >
            {t('menu:logout')}
          </button>
        </div>
      )
    }

    return this.props.children
  }
}

export default withTranslation(ErrorBoundary)

import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Analytic, Pages } from '@app/types'

type PagingProps = {
  pageUrl?: string
  liveData?: Analytic[] | Pages[]
  data?: any[]
  search?: string
  filterActive?: 'warnings' | 'errors'
  open?: boolean
  onLoadMore?(): any // modal
  extraActive?: boolean
  collection?: 'pages' | 'issues'
  blockLive?: boolean
}

export const usePaging = ({
  liveData,
  filterActive,
  search,
  data,
  onLoadMore,
  collection,
  blockLive,
}: PagingProps) => {
  const [selectedIndex, setSelectedIndex] = useState<number | undefined>()
  const [issueIndex, setIndex] = useState<number>(0)
  // Pagination tracking
  const offsetTracking = useRef<{ last: number; next: number }>({
    last: 0,
    next: 0,
  })
  const searchRef = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    setIndex(0)
  }, [filterActive, setIndex])

  useEffect(() => {
    if (search) {
      setIndex(0)
    }
  }, [search, setIndex])

  const { issueSource, liveDataExist } = useMemo(() => {
    const isLive = !!liveData?.length

    return {
      issueSource:
        isLive && !search && !blockLive
          ? liveData
          : data?.length
            ? data
            : liveData || [],
      liveDataExist: isLive,
    }
  }, [liveData, data, search, blockLive])

  const [issueList, stats] = useMemo(() => {
    let base = (issueIndex + 1) * 10

    // offset through the live data
    if (issueIndex) {
      // set the last offset value
      offsetTracking.current.last = offsetTracking.current.next
      base += offsetTracking.current.next
    }

    const items: Analytic[] = new Array(Math.max(issueSource.length, 10))

    let errorCount = 0
    let warningCount = 0
    let totalTTL = 0

    // iterator
    let j = 0
    let i = base - 10
    // offset position
    let offsetTracker = 0

    // loop until ten items filled
    for (; j < 10; i++) {
      const item = issueSource[i]

      if (!item || j === 10) {
        break
      }

      // pages collection
      if (
        collection === 'pages' &&
        item.pageLoadTime &&
        typeof item.pageLoadTime?.duration === 'number'
      ) {
        items[j] = item
        const dur = item?.pageLoadTime?.duration

        if (typeof dur !== 'undefined') {
          totalTTL += item.pageLoadTime?.duration
        }
        j++
      } else if (
        typeof item.warningCount === 'number' &&
        (!filterActive ||
          (filterActive === 'errors' && item.errorCount) ||
          (filterActive === 'warnings' && item.warningCount))
      ) {
        items[j] = item
        errorCount += item.errorCount
        warningCount += item.warningCount
        j++
      } else {
        offsetTracker += 1
      }
    }

    offsetTracking.current.next = offsetTracker
    items.length = j

    return [items, { errorCount, warningCount, totalTTL }]
  }, [issueIndex, issueSource, offsetTracking, filterActive, collection])

  const onPrevSelect = useCallback(() => {
    if (issueIndex) {
      if (liveDataExist) {
        offsetTracking.current.next = offsetTracking.current.last
      }
      setIndex((x: number) => x - 1)
      setSelectedIndex(undefined)
    }
  }, [issueIndex, liveDataExist, offsetTracking, setIndex, setSelectedIndex])

  const idx = (issueIndex + 1) * 10
  const blocked = issueSource.length < idx || issueList.length < 10

  const onLoadEvent = useCallback(async () => {
    // get the next set of data
    if (idx === issueSource.length) {
      try {
        onLoadMore && (await onLoadMore())
      } catch (e) {
        console.error(e)
      }
    }
    setIndex((x: number) => x + 1)
    setSelectedIndex(undefined)
  }, [idx, issueSource, onLoadMore, setIndex, setSelectedIndex])

  const onSearchSubmit = async (e: React.FormEvent) => {
    e?.preventDefault()
    try {
      if (!search) {
        setIndex(0)
        onLoadMore && (await onLoadMore())
      } else {
        onLoadMore && (await onLoadMore())
      }
    } catch (e) {
      console.error(e)
    }
  }

  return {
    data,
    blocked,
    searchRef,
    selectedIndex,
    issueList,
    issueSource,
    stats,
    issueIndex,
    offsetTracking,
    onLoadEvent,
    onPrevSelect,
    onSearchSubmit,
    setSelectedIndex,
    setIndex,
  }
}

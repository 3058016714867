import React, { ReactNode, forwardRef } from 'react'
import NextLink, { LinkProps } from 'next/link'

export interface BaseLink extends LinkProps {
  href: string
  className?: string
  shouldPrefetch?: boolean
  innerRef?: any
  activeClassName?: string
  children?: ReactNode
  title?: string
  target?: string
  rel?: string
  style?: React.CSSProperties
  download?: boolean
}

export const NextComposed = forwardRef(function Link(
  { as, href, ...other }: BaseLink,
  ref?: React.Ref<HTMLAnchorElement>
) {
  return (
    <NextLink
      {...(other ?? {})}
      href={href}
      as={as}
      passHref
      prefetch={
        other.prefetch === false || other.shouldPrefetch === false
          ? false
          : undefined
      }
      ref={ref}
    ></NextLink>
  )
})
